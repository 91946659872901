import { listNavDetauls, listNavEnter, listNavEnter2 } from "@/api";
export default {
  data() {
    return {
      show: false,
      password: "",
      title: "",
      amount: "",
      info: "",
      imgs: ""
    };
  },
  mounted() {
    let id = this.$route.query.id;
    if (id < 5) {
      this.imgs = require(`@/assets/future/future_${id}_bg.png`);
    } else {
      this.imgs = require(`@/assets/future/future_1_bg.png`);
    }
    this.title = this.$route.query.title;
    listNavDetauls(id).then(res => {
      this.info = res.data;
    });
  },
  methods: {
    toDOM(value) {
      const parser = new DOMParser();
      let dom = parser.parseFromString(value, 'text/html').documentElement.textContent;
      return dom;
    },
    openPop() {
      this.show = true;
    },
    closePop() {
      this.show = false;
    },
    submit(info) {
      if (this.password.length < 3) {
        this.$closeToast();
        this.$toast("密码错误");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      if (this.info.item_id == '1') {
        //活动用接口
        listNavEnter2({
          item_id: info.item_id,
          pwd: this.password
        }).then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.show = false;
          this.$store.dispatch('getUserInfo');
        });
      } else {
        // if (this.amount < 1) {
        //   this.$closeToast()
        //   this.$toast("请填写购买金额")
        //   return
        // }
        //正常接口

        listNavEnter({
          item_id: info.id,
          pwd: this.password,
          amount: this.info.min
        }).then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.show = false;
          this.$store.dispatch('getUserInfo');
        });
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};